document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.querySelectorAll('.print-invoice-button')
  if(buttons.length === 0) return;
  const pdfIframe = document.getElementById('fast-print-pdf');
  let currentButton;

  pdfIframe.onload = () => {
    currentButton.disabled = false;
    pdfIframe.contentWindow.print();
  }

  buttons.forEach((button) => {
    button.addEventListener('click', async (e) => {
      currentButton = button;
      e.preventDefault();
      const invoiceId = button.dataset.invoiceId;
      const officialPdfUrl = `/e_invoices/${invoiceId}/pdf_official.pdf`;
      button.disabled = true;

      if(!invoiceId || !officialPdfUrl || officialPdfUrl.length < 1){
        return button.disabled = false;
      }

      pdfIframe.src = officialPdfUrl;

      const parentBtn = button.closest('div');
      const printedCheck = parentBtn.querySelector('.printed-check');
      if(printedCheck) {
        printedCheck.classList.remove('invisible');
      }
    })
  })
})